export const usePageMeta = () => {
  const siteUrl = useRuntimeConfig().public.siteUrl
  const { ogImage } = useGlobalData()
  const { wrapLocale } = useRoutesUtils()
  const gtm = useGtm()
  const store = useRoutesStore()

  const route = useRoute()
  const pageMeta = useState('adyen-page-meta', () => ({
    title: '',
    image: '',
    description: '',
    robots: '',
    url: siteUrl + route.path
  }))

  const setPageMeta = (seoData: any) => {
    if (!seoData) {
      return
    }
    pageMeta.value = {
      title: seoData.title + ' - Adyen',
      image: seoData.ogImage || ogImage.value,
      description: seoData.description,
      robots: seoData.robots,
      url: siteUrl + route.path
    }
  }

  /**
   * trackPage,
   * handles all tracking events on page load
   */
  async function trackPage (locale: string) {
    await store.retrieveRoutes()
    const { getDefaultLocaleRoute } = useRoutes(store.routes, wrapLocale(locale))

    // Skip tracking if not running in the client

    const defaultLocaleRoute = getDefaultLocaleRoute(route?.path, 'en')

    // this.$segment?.page(
    //   defaultLocaleRoute?.payload?.category,
    //   defaultLocaleRoute?.route,
    //   { subcategory: defaultLocaleRoute?.payload?.subcategory }
    // )

    const [lang, region] = locale?.split('_') || []

    const eventData = {
      event: 'virtual_page',
      page: {
        group: defaultLocaleRoute?.payload?.category,
        type: defaultLocaleRoute?.payload?.subcategory,
        path: defaultLocaleRoute?.route,
        locale_path: route?.path,
        full_url: window?.location.href
      },
      site: {
        lang,
        region
      }
    }

    // TODO check
    gtm?.push(eventData)
  }

  return {
    trackPage,
    setPageMeta,
    pageMeta
  }
}
