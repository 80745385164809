import { useI18nPageInfo } from './useI18nPageInfo'

export const usePageSEO = (data: any) => {
  const { isHome } = useRoutesUtils()
  const { i18nParams } = useI18nPageInfo()
  const route = useRoute()

  const paramsKeys = Object.keys(route.params || {})
  const hasSlug = paramsKeys.length > 0
  const setI18nParams = useSetI18nParams()
  setI18nParams(i18nParams.value)

  const i18nHead = useLocaleHead({
    addSeoAttributes: true
  })

  // - Generate alternate link tags only for existing route locales
  const routeLocales = Object.keys(i18nParams.value || {})

  // Not needed after the i18n lib update
  // const routeLocalesIso = routeLocales.length ? routeLocales.map(locale => getLocaleIso(locale)) : locales.map(item => item.iso)

  // TODO: Test the solution for overriding x-default link when we have pages that don't have default locale (en) version

  const i18nHeadLinkFiltered = i18nHead.value?.link.filter((item) => {
    // keep default and canonical link
    if (item.rel === 'canonical' || item.hreflang === 'x-default') {
      return true
    }
    return routeLocales.includes(item.hreflang)
  }).map((item) => {
    // Override x-default link in case the page does not have default locale (en) version
    if (item.hreflang === 'x-default' && !routeLocales.includes('en')) {
      return { ...item, href: i18nHead.value?.link.find(item => item.rel === 'canonical')?.href }
    }
    return item
  })

  // - Remove section name from href of alternate links when target site is not the main website

  // This regex matches /{targetSite} when it is followed by either a slash (/) or the end of the string ($).
  //  const urlReplaceRegex = new RegExp(`/${targetSite}(\\/|$)`, 'g')
  //  const i18nHeadLinkForTargetSite = IS_DEFAULT_TARGET_SITE
  //    ? i18nHeadLinkFiltered
  //    : i18nHeadLinkFiltered.map(item => ({
  //      ...item,
  //      href: item.href.replace(urlReplaceRegex, '/')
  //    }))

  const i18nHeadLinkForTargetSite = i18nHeadLinkFiltered

  // Patch to add tailing slash to home pages alternate links, required by SEO for compatibility with old implementation
  const i18nHeadLinks = isHome(route.path)
    ? i18nHeadLinkForTargetSite
    : i18nHeadLinkForTargetSite.map(item => ({
      ...item,
      href: item.href.endsWith('/') ? item.href : `${item.href}/` // add tailing slash only if it is not present
    }))

  // - Generate  og:locale meta tags only for existing route locales
  const i18nHeadMetaFiltered = i18nHead?.value?.meta.filter((item) => {
    // TODO: remap meta tags to avoid having content
    const route = i18nParams.value?.[item.content]
    if (!route) {
      return false
    }

    let translatedRouteExists = true
    paramsKeys.forEach((key) => {
      if (!route[key]) {
        translatedRouteExists = false
      }
    })
    return translatedRouteExists
  }) ?? []

  // [...(i18nHead.value.meta ||
  // useHead is a Nuxt 3 out-of-the-box composable
  useHead({
    htmlAttrs: {
      lang: i18nHead?.value?.htmlAttrs.lang || 'en'
    },
    link: [...(i18nHeadLinks || [])],
    meta: [...i18nHeadMetaFiltered]
  })
}
