import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataArticleAuthor } from '../../../core/data-layer/global-data/fragments'
import fragmentPageKnowledgeHubDetail from '../pages/page-knowledge-hub-detail/fragment-page-knowledge-hub-detail'
import fragmentBlockKnowledgeHubChapter from '../blocks/block-knowledge-hub-chapter/fragment-block-knowledge-hub-chapter'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentGlobalDataArticleAuthor}
    ${fragmentPageKnowledgeHubDetail}
    ${fragmentBlockKnowledgeHubChapter}
    fragment ContentKnowledgeHubArticleFragment on ContentKnowledgeHubArticle
    {
        sys {
            id
        }
        displayFullWidthImageHero
        legacyContent
        intro {
            json
        }
        image {
            contentType: __typename
            ...ModuleMediaFragment
        }
        fpZoom
        author {
            contentType: __typename
            ...GlobalDataArticleAuthorFragment
        }
        relatedCollection (limit: 3){
            items {
                contentType: __typename
                ...PageKnowledgeHubDetailFragment
            }
        }
        chaptersCollection (limit: 10) {
            items {
                contentType: __typename
                ...BlockKnowledgeHubChapterFragment
            }
        }
    }
`
