export const useCareersPageData = () => {
  const dataLayer = useCareersDataLayer()
  const { fetchGlobalDataForContentType } = useCareersGlobalData()

  const { useAsyncDataWithQuery, useRelatedPagesAsyncDataWithQuery, useStandardAsyncData, onAfterAdyenData, onBeforeAdyenData, pageData, content } = usePageData('careers', {
    layerMappers: dataLayer.mappers,
    layerFragments: {},
    fetchGlobalDataFunction: fetchGlobalDataForContentType
  })

  return {
    pageData,
    content,
    onBeforeAdyenData,
    onAfterAdyenData,
    useAsyncDataWithQuery,
    useRelatedPagesAsyncDataWithQuery,
    useStandardAsyncData
  }
}
