import type { ContentTypeName } from '../../../core/types'
import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import { mapperGlobalDataArticleAuthor } from '../../../core/data-layer/global-data/mappers'
import { ContentKnowledgeHubArticle } from '../../../core/data-layer/definitions/knowledgeHub'
import mapperBlockKnowledgeHubChapter from '../blocks/block-knowledge-hub-chapter/mapper-block-knowledge-hub-chapter'
import { mapperKnowledgeHubArticlesSummaries } from '../summary/mapper-knowledge-hub-articles-summaries'
import imageSizes from '../../../core/constants/imageSizes'

const relatedContentCardsImageOptions = {
  resize: true,
  lazy: true,
  sizes: {
    small: { width: 272, height: 153, aspectRatio: '16/9' }, medium: { width: 576, height: 324, aspectRatio: '16/9' }, large: { width: 576, height: 324, aspectRatio: '16/9' }
  }
}

const authorImageOptions = {
  resize: true,
  lazy: true,
  sizes: imageSizes.author_component
}

/**
 * Page Campaigns Detail Awareness Mapper
 * Normalizes data to be used in campaigns detail awareness pages
 */
export const mapperContentKnowledgeHubArticle = (data: any, options: any) => {
  if (!data) {
    return null
  }

  if (data.lazy) {
    return data
  }

  const mapItem = (item: any, options: any) => {
    if (!item) {
      return
    }
    const contentType: ContentTypeName = item.contentType
    if (contentType === 'GlobalDataArticleAuthor') {
      return {
        ...mapperGlobalDataArticleAuthor(item, options),
        type: contentType
      }
    } else if (contentType === 'ModuleMedia') {
      return mapperModuleMedia(item, options)
    } else if (contentType === 'BlockKnowledgeHubChapter') {
      return {
        ...mapperBlockKnowledgeHubChapter(item, options),
        type: contentType
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Mapper missing for content type for ${JSON.stringify(item)}`)
    }
  }

  const imageOptions = ContentKnowledgeHubArticle.fields.find(item => item.id === 'image')?.imageOptions ?? []
  const optionsWithZoom = imageOptions.length > 0 ? imageOptions.map(option => ({ ...option, zoom: data.fpZoom })) : {}

  return {
    displayFullWidthImageHero: data.displayFullWidthImageHero,
    id: data?.sys?.id || '',
    headerMedia: mapItem(data.image, optionsWithZoom),
    chaptersIntroText: data.intro || null,
    author: mapItem(data.author, authorImageOptions) || null,
    chapters: data.chaptersCollection?.items?.filter(Boolean).map((item: any) => mapItem(item, options)) || [],
    // Standardize `related content` object to use directly `KnowledgeHubRelatedContent` component
    relatedContent: {
      filtersQuery: null,
      items: mapperKnowledgeHubArticlesSummaries(data.relatedCollection?.items, { ...options, imageOptions: relatedContentCardsImageOptions })
    },
    legacyContent: data.legacyContent || ''
  }
}
