<template>
  <ClientOnly>
    <ais-instant-search
      v-if="algolia"
      :search-client="algolia"
      :index-name="mainIndex"
      class="search"
    >
      <ais-configure
        :hits-per-page.camel="resultsPerPage"
        :attributes-to-snippet.camel="['pageContent:20', 'content:20', 'body_safe:20']"
        :snippet-ellipsis-text.camel="'…'"
      />
      <div class="ds-grid ds-justify-content-center ds-height-full">
        <div class="ds-grid__col ds-grid__col--12 ds-md-grid__col--10 ds-height-full ds-display-flex ds-flex-direction-column">
          <ais-search-box
            class="search__search-box"
            :class-names="{ 'ais-SearchBox-resetIcon' : 'this-is-a-nice' }"
          >
            <template #default="{ currentRefinement, refine }">
              <div class="search__input">
                <div class="ds-field-layout">
                  <DsIconFrame
                    size="48"
                    align-y="center"
                  >
                    <DsIconCore
                      category="interface"
                      name="search"
                    />
                  </DsIconFrame>
                  <input
                    ref="searchInput"
                    :value="currentRefinement"
                    class="ds-field ds-field--light"
                    type="search"
                    :placeholder="labels.search"
                    @input="refine($event.currentTarget.value)"
                  >
                  <button
                    v-if="currentRefinement"
                    class="ds-button-icon ds-button-icon--ghost"
                    @click.stop="refine('')"
                  >
                    <DsIconFrame
                      size="48"
                      align-y="center"
                    >
                      <DsIconCore
                        category="interface"
                        name="cross-circle-fill"
                        class="ds-color-grey-1300"
                      />
                    </DsIconFrame>
                  </button>
                </div>
              </div>
            </template>
          </ais-search-box>
          <DsTabs
            :screen="screen"
            :label="labels.search"
            class="search__tabs ds-margin-top-24"
            @item-clicked="handleTabItemClicked"
          >
            <DsTabItem
              v-for="indexName in altIndices"
              :key="`tab-${indexName}`"
              :title="`${getIndexTitle(indexName)}`"
              class="search__results"
            >
              <ais-index
                :index-name="indexName"
                class="search__results__scroll ds-width-full test-ais-index"
              >
                <ais-stats>
                  <template #default="{ nbHits, query }">
                    <Teleport :to="`#tab-${kebabCase(getIndexTitle(indexName))}`">
                      <span v-show="query">
                        &nbsp; ({{ nbHits }})
                      </span>
                    </Teleport>
                  </template>
                </ais-stats>
                <ais-configure
                  v-if="indicesWithLocaleFacet.includes(indexName)"
                  :facets="['locale']"
                  :facet-filters.camel="[`locale:${localeIso}`]"
                />
                <ais-state-results>
                  <template #default="{ results: { hits, query }}">
                    <SearchResult v-show="query && hits.length > 0" :index-name="indexName" />
                    <div v-if="query && hits.length === 0">
                      {{ localeGlobalContentData.filterLabels.noResultsWereFoundFor }} <strong>{{ query }}</strong>
                    </div>
                  </template>
                </ais-state-results>
              </ais-index>
            </DsTabItem>
          </DsTabs>
        </div>
      </div>
    </ais-instant-search>
  </ClientOnly>
</template>

<script setup>
import { DsIconFrame, DsIconCore, DsTabs, DsTabItem } from 'awds'
import { AisInstantSearch, AisConfigure, AisSearchBox, AisIndex, AisStats, AisStateResults } from 'vue-instantsearch/vue3/es'
import { kebabCase } from 'lodash-es'
import SearchResult from './SearchResult.vue'

const RESULTS_PER_PAGE = 10

const { locale: localeRef } = useI18n()
const { getLocaleIso } = useLocales()
const { localeGlobalContentData, labels } = useGlobalData()
const algolia = useAlgoliaRef()

const resultsPerPage = ref(RESULTS_PER_PAGE)

const searchIndices = useRuntimeConfig().public.algoliaSearchIndices?.split(',')
const searchInput = ref(null)
const altIndices = ref(searchIndices)
const mainIndex = ref(searchIndices[0])
const selectedIndexTitle = ref('')

defineProps({
  screen: { type: String, required: true }
})

const indexMap = [
  { substring: 'website', labelKey: 'website' },
  { substring: 'knowledge', labelKey: 'knowledgeHub' },
  { substring: 'investor', labelKey: 'productionInvestorsAdyen' },
  { substring: 'help', labelKey: 'productionHelpAdyenCom' },
  { substring: 'docs', labelKey: 'docsGrav' },
  { substring: 'api', labelKey: 'apiExplorer' }
]

const searchData = computed(() => {
  return localeGlobalContentData.value.search
})

/**
 * This method retrieves the label for the tabs
 * Since we don't have an exact nomenclature pattern for Algolia indices,
 * we use historically used substrings to check against the index name and get the appropriate searchData label.
 * If the index name does not have a match we will use it as the label
 * @param indexName
 * @return searchData label or indexName
 */
const getIndexTitle = (indexName) => {
  const index = indexName.toLowerCase()
  const labelKey = indexMap.find(i => index.includes(i.substring))?.labelKey
  return searchData.value[labelKey] || indexName
}
selectedIndexTitle.value = getIndexTitle(mainIndex.value)
const indicesWithLocaleFacet = ref(altIndices.value.filter(index => index.includes('website') || index.includes('knowledge')))

const localeIso = computed(() => {
  return getLocaleIso(localeRef.value)
})

const handleTabItemClicked = (event) => {
  selectedIndexTitle.value = event.label.replace(/\([0-9]+\)/, '').trim()
}

</script>

<style lang="scss" scoped>
@import 'awds/foundation-vars.scss';

.search {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__search-box {
    width: 100%;

    :deep() .ds-field-layout {
      .icon-frame {
        left: 0;
      }

      .ds-button-icon {
        position: absolute;
        right: 0;
      }
    }
  }

  &__tabs :deep() {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .ds-tabs__container {
      height: 100%;
      overflow-y: auto;
      padding: $spacing-24 0;
      width: 100%;
    }
  }

  :deep(mark) {
    background: #ffba5966;
  }
}
</style>
